// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { register as registerServiceWorker } from './serviceWorkerRegistration';
import Offline from './common/components/offline.component';
import { NotebookProvider } from './Components/Context/book.context';
import { CodeProvider } from './Components/Context/code.context';

// import * as serviceWorker from './service-worker';
import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    {/* <Offline> */}
    <CodeProvider>
    <NotebookProvider>
        <App/>
    </NotebookProvider>
    </CodeProvider>
    {/* </Offline> */}
    {/* <script src="/js/pwa.js"></script> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorkerRegistration.unregister()
registerServiceWorker()