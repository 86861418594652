import { ChapterContainer,ButtonContainer,LinkContainer,LinkItem } from "../chapters.styles";
import { useState,useEffect,useContext } from "react";
import { NotebookContext } from "../../Context/book.context";
import { CodeContext } from "../../Context/code.context";
import { LZString } from "../../../lz-string";

// --- DO NOT DELETE THIS SECTION --- //
// import { predictions } from "./blogPredictionsImports";
// import { printing } from "./blog3dPrintingImports";
// import { gadGets } from "./blogGadgetImports";
// ---------------------------------- //

import React from "react";
// import axios from 'axios'

const BlogChapters = ({state,setState}) => {

    const [localState,setLocalState] = useState({
        currentChapter:[],
        sections:[],
        currentSelection:'',
        search:'',
    })
    const { 
        currentSelection,
        currentChapter,
        search,
        sections
    } = localState

    const {blog,setNotebookName} = useContext(NotebookContext)
    // const {  } = 

    // DO NOT DELETE THIS SECTION //
    //     const sections = [
    //     ['Predictive Analytics',predictions],
    //     ['3d Modeling & Printing',printing],
    //     // ['Random Gadgets',gadGets]
    // ]
    // ---------------------------- /
    
    useEffect(() => {
        try {
            downloadBooks()
        } catch (error) {
            console.log('There was a serious error',error)
        }
    },[])

    const findSections = (array) => {
        let list = []
        array.map((el,i) => {
            list.push(el.sub_category)
        })
        let uniq = list.filter((element,index) => {
            return list.indexOf(element) === index
        })
        return uniq
    }

    // --- DOWNLOAD AND STORE NOTEBOOKS LOCALLY (PULLED FROM NOTEBOOK CONTEXT) --- //
    const downloadBooks = (value) => {
        const blogItems = blog.filter(el => el.category === 'Blog')
        const sections = findSections(blogItems)

        setLocalState({
        ...localState,
        currentSelection:'Predictive Analytics',
        currentChapter:blogItems,
        sections:sections,
        search:'',
    })
    }

    const location = window.location.pathname.split('/')
    
    useEffect(() => {

        try {
            if(state.wasRouted === true) {
                const category = location[2].split('%20').join(' ')
                const docTitle = location[3].split('%20').join(' ')
                const section = sections.filter(el => {return el[0] === category})
                const document = section[0][1].filter(el => {return el.name === 'Involute Gear Calculator'})
                switchView(section[0])
                setState({
                    ...state,
                    wasRouted:false,
                    rawIpynb:document[0]
                })
            }
        } catch (err) {
            // This operation requires the actual title to be included in the address bar
            return console.log('This value was not entered or is incorrect')
        }
    })

    // THIS IS FOR VIEWING THE ACTUAL IPYNB FILES AND ADDING TO DB //
    // const switchView = (val) => {
    //     setLocalState({
    //         ...localState,
    //         currentSelection:val[0],
    //         currentChapter:val[1]
    //     })
    // }

    const switchView = (val) => {
        setLocalState({
            ...localState,
            currentSelection:val,
        })
    }

    //  --- ADDING NEW NOTEBOOKS --- //
    const writeToDB = async (el) => {
        const { name,source } = el
        // const { cells, metadata, nbformat, nbformat_minor} = source
        var sourceString = JSON.stringify(source)
        const half = Math.ceil(sourceString.split('').length/2)
        const compressedString = await (LZString.compress(sourceString))
        console.log('showing compressed string',compressedString.length)

        // const sourceArray = sections[1][1]
        const category = "Blog"
        // const sub_category = '3d Modeling & Printing'
        const sub_category = '3d Modeling & Printing'
        // axios.post('/api/addnotebook/large',{category,sub_category,name,sourceString}).then(res => {})
        // const sourceArray = sourceString.split('')
        // navigator.clipboard.writeText(sourceString)
        // const cellString = JSON.stringify(cells)
        // await axios.post('/api/addnotebook/large',{category,name,compressedString,sub_category,}).then(res => {})
    }

    const selectedDocs = currentChapter.filter(el => el.sub_category === currentSelection)

    const mappedDocs = selectedDocs.map((el,i) => {
        const item = {name:el.name,source:(JSON.parse(el.source))}
        return(
            <LinkItem 
                key={i} 
                onClick={() => {
                    setState({...state,rawIpynb:item})
                    setNotebookName(item.name)
                    }
                } 
                state={state} 
                name={el.name} 
                sub_category={el.sub_category}
            >
                {el.name}
            </LinkItem>
        )
    })

    const searchedItems = sections.filter(el => {
        return el.toLowerCase().includes(search.toLowerCase())
    })

    const mappedSections = searchedItems.map((el,i) => {
        return <button key={i} onClick={() => switchView(el)}>{el}</button>
    })

    const searchHandler = (e) => {
        e.preventDefault()
        const {name,value} = e.target
        setLocalState({...localState,[name]:value})
    }

    return (
        <ChapterContainer>
            <ButtonContainer>
                {mappedSections}
            </ButtonContainer>

            <input
                type="text"
                value={search}
                name="search"
                onChange={searchHandler}
            />

            <LinkContainer>
                <h4>Blog: {currentSelection}</h4>
                {mappedDocs}
            </LinkContainer>

        {/* THIS IS FOR ADDING ITEMS IN DADABASE */}
            {/* <LinkContainer>
                <h4>WRITE TO DB: {currentSelection}</h4>
                {!currentChapter != null && currentChapter.map((el,i) => {
                    return(<LinkItem key={i} onClick={() => writeToDB(el)} state={state} name={el.name}>{el.name}</LinkItem>)
                })}
            </LinkContainer> */}

        </ChapterContainer>
    )
}

export default BlogChapters