import styled from 'styled-components'
import { colorPallet } from '../../assets/colors'
const{ orange } = colorPallet

export const HeadingContainer = styled.header`
    max-width:800px;
    height:80px;
    margin:auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	align-content: stretch;

    section {
        min-width:300px;
        max-width:800px;
        display:flex;
        justify-content:space-around;
    }

    h1 {
        font-size:32px;
        height:40px;
        font-weight:200;
        color:#555;
        text-align:left;
        padding-left:5px;
        // font-family: 'Silkscreen', cursive;
        // font-family: 'Cairo', sans-serif;
        // font-family: 'Plus Jakarta Sans', sans-serif;
        margin:0px;
        color:${orange};

        @media (max-width:800px) {
            font-size:26px;
            font-weight:800;
        }
    }

    a {
        color:blue;
        font-style:italic;
        text-decoration:none;
    }
`