import styled from 'styled-components'

import {
    copyText,
    gitBash,
    info
} from "./SVG";

import {
    orange
} from '../../global-styles/gstyles.styles';

const DefaultSVG = styled.div`
    color:${orange};
    margin:auto;
`


const SVG_CLASS = {
    copyText:'copyText',
    gitBash:'gitBash',
    info:'info'
}

const getSVGClass = (svgClass) => ({
    [SVG_CLASS.gitBash]:gitBash,
    [SVG_CLASS.copyText]:copyText,
    [SVG_CLASS.info]:info,
}[svgClass])


const BaseIcon = ({iconClass,...otherProps}) => {
    const IconClass = getSVGClass(iconClass)
    return (
        <DefaultSVG
            {...otherProps}
        >
            {IconClass()}
        </DefaultSVG>
    )
}

export default BaseIcon