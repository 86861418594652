import { NoticeContainer } from './notice.styles'
import { useState,useEffect,useContext } from 'react'
import { NotebookContext } from '../../Context/book.context'

const Notice = () => {

    const [ text, setText ] = useState('')
    const { noticeContent, setNoticeContent} = useContext(NotebookContext)

    useEffect(() => {update()},[noticeContent])
    const update = () => {
            if (noticeContent != null) {
            setText(noticeContent)
            setNoticeContent(null)
        }
    }

    return (
        <NoticeContainer noticeContent={noticeContent}>
            <strong>{text}</strong>
        </NoticeContainer>
    )
}

export default Notice