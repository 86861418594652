import styled from 'styled-components'

export const AppContainer = styled.main`
    position:relative;
    text-align: center;
    max-width:800px;
    margin:auto;
    min-height:100vh;    
    display:flex;
    flex-direction:column;
    // background-color:blue;
`

// export const DisplayOptions = styled.div`
//     // height:50px;
//     padding:10px;
//     position:sticky;
//     top:50px;
//     border-top:solid 1px;
//     border-bottom:solid 1px;
//     label {margin-right:5px;}

//     display:flex;
//     justify-content:space-around;
// `
// style={{position:'relative',width:'100%',height:'50px',backgroundColor:'green'}}