import styled from "styled-components"

export const DisplayOptionsContainer = styled.header`
    // height:50px;
    padding:10px;
    position:sticky;
    top:50px;
    border-top:solid 1px;
    border-bottom:solid 1px;
    label {margin-right:5px;};
    background-color:#fff;
    z-index:1;
    max-width:800px;
    margin:auto;

    display:flex;
    justify-content:space-around;
`