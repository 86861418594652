import styled from 'styled-components'
import { colorPallet } from '../../assets/colors'
import { paper,orange } from '../../global-styles/gstyles.styles'
// const { orange } = colorPallet


export const CatContainer = styled.div`
    max-width:800px;
    margin:auto;
    height:${({isOnline}) => !isOnline ? '75' : '50'}px;
    display:flex;
    border-top:solid 1px;
    position:sticky;
    top:0px;
    background-color:#fff;
    z-index:1;
`

export const SelectedCat = styled.i`
    color:${orange};
    font-size:12px;
    font-weight:${({selectedCategory,id}) => selectedCategory === id ? '800' : '200'};
    margin:auto;
`

export const UpdateNotice = styled.button`
    color:${orange};
    background-color:${paper};
    position:absolute;
    font-size:12px;
    top:-8px;
    left:10px;
    border:solid 1px;
    border-radius:5px;
    z-index:100000000000;
    padding-right:2px;

    padding-left:2px;
    box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    i {
        animation: blinker 1s linear infinite;
    
        @keyframes blinker {
            50% {
              opacity: .6;
            }
          }
    }
    // height:50px;
    // width:100px;
`

export const OfflineTag = styled.h4`
    text-align:center;
    margin:auto;
    // position:sticky;
    top: 0px;
    color:#fff;
    background: rgba(0,0,0,0.5);
    z-index:1;
    position:absolute;
    width:100%;
`