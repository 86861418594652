import React from 'react'
import { LoadingContainer,LoadingText,LoadingOverlay } from './loading.styles'

const Loading = (props) => {
  return (
    <LoadingContainer>
      <LoadingOverlay></LoadingOverlay>
      <img
        src="https://i.pinimg.com/originals/a4/f2/cb/a4f2cb80ff2ae2772e80bf30e9d78d4c.gif"
        alt="loading"
      />
      <LoadingText>PLEASE WAIT...</LoadingText>
    </LoadingContainer>
  )
}
export default Loading
