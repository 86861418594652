import { useContext } from "react";
import { DisplayOptionsContainer  } from "./display.styles";
import { CodeContext } from "../Context/code.context";
import { NotebookContext } from "../Context/book.context";
import BaseIcon from "../SVG/svg.component";

const DisplayOptions = () => {

    const { setCodeContextState,codeContextState } = useContext(CodeContext)

    const { setNoticeContent,notebookName } = useContext(NotebookContext)

    const copy = (location) => {
        setNoticeContent(`copied link`)
        const formatLocation = location.split(' ').join('%20')
        navigator.clipboard.writeText(formatLocation)
      }

    return (
        <DisplayOptionsContainer>
                      <div style={{
            // backgroundColor:'blue',
            margin:'auto',
            height:'18px'
            }}>
            {/* <label htmlFor="displaySource">Source code</label> */}
            <select
              name="displaySource"
              onChange={e => {
                setCodeContextState({...codeContextState, displayPython: e.target.value})
              }}
            >
              <option value="true">show code</option>
              <option value="false">hide code</option>
            </select>
          </div>
          <BaseIcon
            iconClass={'copyText'}
            onClick={(e) => copy(window.location.origin+'/'+notebookName)}
          />
        </DisplayOptionsContainer>
    )
}

export default DisplayOptions