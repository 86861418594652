import styled from 'styled-components'

export const BaseMessageContainer = styled.div`
    border-radius:10px;
    display:flex;
    z-index:1000000000000000000000000;
    box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
    top:125px;
    margin:auto;
    background-color:#fff;
    position:relative;
    height:200px;
    width:290px;

    strong {
        text-align:center;
        margin:auto;
        padding:5px;
    }    

    @media (max-height:330px) {
        top:0px;
    }
`