import styled from 'styled-components'

import { BaseMessageContainer } from '../message.styles'
import { Overlay } from '../../../global-styles/gstyles.styles'

export const AlertBox = styled(BaseMessageContainer)`
    display:flex;
    flex-direction:column;
    z-index:3;

    button {
        width:100px;
        height:25px;
        margin:auto;
    }

    strong {
        font-weight:400;
    }
`
    
export const AlertOverlay = styled(Overlay)`
    pointer-events: auto;
`