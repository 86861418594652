import React, {useState,useContext,useEffect} from 'react';
// import Home from './home.component';
import routes from './routes';
import { useBooleanState } from 'webrix/hooks';
// import { OfflineTag } from './App.styles';
import { NotebookContext } from './Components/Context/book.context';
import { CodeContext } from './Components/Context/code.context'
import Heading from './Components/Heading/heading.components';
import Categories from './Components/Categories/cats.components';
import Loading from './Components/Loading/loading.component';
import Alert from './Components/Messages/Alert/alert.component';
import Notice from './Components/Messages/Notice/notice.component';
import DisplayOptions from './Components/DisplayOptions/display.component';
import Footer from './Components/Footer/footer.component';
import { Overlay } from './global-styles/gstyles.styles';

function App() {

  const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(navigator.onLine);

  const { setIsOnline,isLoading,alert,noticeContent } = useContext(NotebookContext)

  const { codeContextState,setCodeContextState } = useContext(CodeContext)
  const { displayPython } = codeContextState

  useEffect(() => {

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);
    setIsOnline(online)

    return () => {
        window.removeEventListener('online', setOnline);
        window.removeEventListener('offline', setOffline);
    };
},[online])

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      {alert &&<Overlay><Alert /></Overlay>}
      <Overlay><Notice/></Overlay>
      <Heading />
      <Categories />
      <DisplayOptions/>
      {routes}
      <Footer />
    </React.Fragment>
  )
}

export default App;