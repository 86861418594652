import styled from 'styled-components'
import { colorPallet } from '../../assets/colors'
const { orange } = colorPallet

export const ChapterContainer = styled.section`
    max-width:800px;
    min-height:300px;
    margin-bottom:10px;
`

export const ButtonContainer = styled.div`
    margin-top:5px;
    button {
        margin:2px;
    }
`

export const LinkContainer = styled.ul`
    display:flex;
    flex-direction:column;
    width:250px;
    min-height:200px;
    margin-bottom:auto;
    text-align:left;

    // li {
    //     margin-top:5px;
    //     font-style:italic;
    // }
`

export const LinkItem = styled.li`
    background-color:${({state,name}) => state.rawIpynb.name === name ? `${orange}` : '#fff'};
    margin-top:5px;
    font-style:italic;
`