import { CatContainer,SelectedCat,UpdateNotice,OfflineTag } from "./cats.styles";
import React, { useContext } from "react";
import { NotebookContext } from "../Context/book.context";
import BaseIcon from "../SVG/svg.component";

const noticeMessage = 'This app is made for offline viewing. To download the latest updates, you will need to first close all the tabs displaying this app, and then re-enter the address to this site in the address bar.'

const Categories = ({state,setState}) => {
    const {
        blog,callBlogs,
        isLoading,
        updateAvailable, 
        selectedCategory,setSelectedCategory,
        isOnline,
        setAlert
    } = useContext(NotebookContext)

    const changeHandler = (param) => {
        setSelectedCategory(param)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <CatContainer isOnline={isOnline}>
            {!isOnline && <OfflineTag>YOU ARE OFFLINE</OfflineTag>}
            {updateAvailable && isOnline && <UpdateNotice
                onClick={() => callBlogs()}
            >
                <i>update available!</i>
            </UpdateNotice>}
            {blog ?
            <>
                <SelectedCat onClick={() => changeHandler('ml')} selectedCategory={selectedCategory} id={'ml'}>Machine Learning</SelectedCat>
                <SelectedCat onClick={() => changeHandler('blog')} selectedCategory={selectedCategory} id={'blog'}>Blog</SelectedCat>
                <SelectedCat onClick={() => changeHandler('math')} selectedCategory={selectedCategory} id={'math'} >Math</SelectedCat>
                <BaseIcon
                    iconClass={'info'}
                    onClick={() => setAlert(noticeMessage)}
                />
            </>
            :
            (!isLoading && <button onClick={() => callBlogs()} style={{margin:'auto'}}>view notebooks</button>)
            }
        </CatContainer>
    )
}

export default Categories