import { ChapterContainer,ButtonContainer,LinkContainer,LinkItem } from "../chapters.styles";
import { useContext,useEffect } from "react";
import { NotebookContext } from "../../Context/book.context";
import axios from "axios";
// import { mlRegression } from "./mlRegressionImports";
// import { mlClassification } from "./mlclassificationImports";
import { useState } from 'react'

const MachineLearning = ({state,setState}) => {

    const [localState,setLocalState] = useState({
        currentSelection:'',
        currentChapter:[],
        sections:[],
    })
    const { currentSelection,currentChapter,sections } = localState

    const {blog,setNotebookName} = useContext(NotebookContext)

    useEffect(() => {
        try {
            downloadBooks()
        } catch (error) {
            console.log('There was a serious error',error)
        }
    },[blog])

    const findSections = (array) => {
        let list = []
        array.map((el,i) => {
            list.push(el.sub_category)
        })
        let uniq = list.filter((element,index) => {
            return list.indexOf(element) === index
        })
        return uniq
    }

    // --- DOWNLOAD AND STORE NOTEBOOKS LOCALLY (PULLED FROM NOTEBOOK CONTEXT) --- //
    const downloadBooks = async (value) => {
        const blogItems = await blog.filter(el => el.category === 'Machine Learning')
        // const blogItems = mlClassification
        const sections = await findSections(blogItems)

        setLocalState({
            ...localState,
            currentChapter:blogItems,
            sections:sections,
            currentSelection:'Regression',
        })
    }

        //  --- ADDING NEW NOTEBOOKS --- //
    const writeToDB = (el) => {
        const { name,source } = el
        console.log('name',name)
        // console.log('source',source)

        // const sourceArray = sections[1][1]
        const category = "Machine Learning"
        // const sub_category = '3d Modeling & Printing'
        const sub_category = 'Classification'
        axios.post('/api/addnotebook',{category,sub_category,name,source}).then(res => {})
    }

    const selectedDocs = currentChapter.filter(el => el.sub_category === currentSelection)

    const mappedDocs = selectedDocs.map((el,i) => {
        // console.log('mapped doc elements',el.sub_category === currentSelection)
        const item = {name:el.name,source:(JSON.parse(el.source))}
        return(
            <LinkItem 
                key={i} 
                onClick={() => {
                    setState({...state,rawIpynb:item})
                    setNotebookName(item.name)
                    }
                } 
                state={state} 
                name={el.name} 
                sub_category={el.sub_category}
            >
                {el.name}
            </LinkItem>
        )
    })
    
    return (
        <ChapterContainer>

            <ButtonContainer>
                {sections.map((el,i) => {
                    return <button key={i} onClick={() => setLocalState({...localState,currentSelection:el})} >{el}</button>
                })}
            </ButtonContainer>

            <LinkContainer>
                <h4>Machine Learning:{currentSelection}</h4>
                {mappedDocs}
                {/* {currentSelection === "Regression" && mlRegression.map((el,i) => (
                    <LinkItem key={i} onClick={() => setState({...state,rawIpynb:el})} state={state} name={el.name}>{el.name}</LinkItem>
                ))}
                {currentSelection === "Classification" && mlClassification.map((el,i) => (
                    <LinkItem key={i} onClick={() => setState({...state,rawIpynb:el})} state={state} name={el.name}>{el.name}</LinkItem>
                ))} */}
            </LinkContainer>

            {/* <LinkContainer>
                <h4>Machine Learning (ADD ITEMS): {currentSelection}</h4>
                {currentSelection === "Regression" && mlClassification.map((el,i) => (
                    <LinkItem key={i} onClick={() => writeToDB(el)} state={state} name={el.name}>{el.name}</LinkItem>
                ))}
            </LinkContainer> */}

        </ChapterContainer>
    )
}

export default MachineLearning