import { ChapterContainer,ButtonContainer,LinkContainer,LinkItem } from "../chapters.styles";
import { useState,useContext,useEffect } from "react";
import { NotebookContext } from "../../Context/book.context";
import { LZString } from "../../../lz-string";
// import { calc } from "./calcImports";
// import { alg1 } from "./alge1Imports";
// import { alg2 } from "./alg2Imports";
// import { trig } from "./trigImports";
// import { numTheory } from "./numTheoryImports";
// import { probs } from "./probabilityImports";
// import { trigArt } from "./trigArtImports";
// import { misc } from "./miscImports";
import React from "react";
import axios from "axios";

const MathChapters = ({state,setState,setNewIpynbItem}) => {

    const [localState,setLocalState] = useState({
        // currentSelection:'Calculus',
        // currentChapter:calc
        currentSelection:'',
        currentChapter:[],
        sections:[]
    })
    const { currentSelection,currentChapter,sections } = localState

    const {blog,setNotebookName} = useContext(NotebookContext)
    // const sections = [
    //     ['Calculus',calc],
    //     ['Algebra',alg1],
    //     ['Trigonometry',trig],
    //     ["Number Theory",numTheory],
    //     ["Probability",probs],
    //     ["Algebra 2",alg2],
    //     ["Art From Trigonometry",trigArt]
    // ]

    useEffect(() => {
        try {
            downloadBooks()
        } catch (error) {
            console.log('There was a serious error',error)
        }
    },[])

    const switchView = (val) => {
        const getAllFromCat = blog
        setLocalState({
            ...localState,
            currentSelection:val,
        })
    }

    const findSections = (array) => {
        let list = []
        array.map((el,i) => {
            list.push(el.sub_category)
        })
        let uniq = list.filter((element,index) => {
            return list.indexOf(element) === index
        })
        return uniq
    }

    const downloadBooks = (value) => {
        const blogItems = blog.filter(el => el.category === 'Math')
        const sections = findSections(blogItems)

        setLocalState({
        ...localState,
        currentSelection:'Calculus',
        currentChapter:blogItems,
        sections:sections,
        // search:'',
    })
    }

    //  --- ADDING NEW NOTEBOOKS --- //
    // CHANGE IPYNB SIZE WITH THESE PARAMETERS FOR HANDLING LARGE FILES//
    //     plt.rcParams['figure.dpi'] = 50
    //      plt.rcParams['savefig.dpi'] = 0
    //      plt.rcParams['figure.figsize'] = [6.0, 4.0]
    // ------------------------------------ //
    const writeToDB = async (el) => {
        const { name,source } = el
        // const { cells, metadata, nbformat, nbformat_minor} = source
        var sourceString = JSON.stringify(source)
        const half = Math.ceil(sourceString.split('').length/2)
        const compressedString = await (LZString.compress(sourceString))

        // const sourceArray = sections[1][1]
        const category = "Math"
        // const sub_category = '3d Modeling & Printing'
        const sub_category = 'Art From Trigonometry'
        // axios.post('/api/addnotebook/large',{category,sub_category,name,sourceString}).then(res => {})
        // const sourceArray = sourceString.split('')
        // navigator.clipboard.writeText(sourceString)
        // const cellString = JSON.stringify(cells)
        // await axios.post('/api/addnotebook/large',{category,name,compressedString,sub_category,}).then(res => {})
    }

    const selectedDocs = currentChapter.filter(el => el.sub_category === currentSelection)

    const mappedDocs = selectedDocs.map((el,i) => {
        // console.log('mapped doc elements',el.sub_category === currentSelection)
        const item = {name:el.name,source:(JSON.parse(el.source))}
        return(
            <LinkItem 
                key={i} 
                onClick={() => {
                        setState({...state,rawIpynb:item})
                        setNotebookName(item.name)
                    }
                } 
                state={state} 
                name={el.name} 
                sub_category={el.sub_category}
            >
                {el.name}
            </LinkItem>
        )
    })

    return (
        <ChapterContainer>

            <ButtonContainer>
                {sections.map((el,i) => {
                    return <button key={i} onClick={() => switchView(el)}>{el}</button>
                })}
            </ButtonContainer>

            <LinkContainer>
                <h4>Mathematics:{currentSelection}</h4>
                {mappedDocs}
            </LinkContainer>

            {/* <LinkContainer>
                <h4>WRITE TO DB: {currentSelection}</h4>
                {!currentChapter != null && currentChapter.map((el,i) => {
                    return(<LinkItem key={i} onClick={() => writeToDB(el)} state={state} name={el.name}>{el.name}</LinkItem>)
                })}
            </LinkContainer> */}

        </ChapterContainer>
    )
}

export default MathChapters