import styled from "styled-components";

export const OfflineContainer = styled.div`
    // display:flex;
    // flex-direction:column;
    // width:100%;
    // background-color:red;
`

export const OfflineTag = styled.strong`
    margin:auto;
    // width:1000px;
    // background-color:blue;
    color:#fff;
    background: rgba(0,0,0,0.2);
`