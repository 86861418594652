import { FooterContainer,RightColumn,ImageWrapper } from "./footer.styles"
import MMLogo from '../../assets/mstile-150x150.png'

export const Footer = () => {

    return (
        <FooterContainer>
            <a
                href="http://madmodels3d.com/#/"
                target="_blank"
            >
                <ImageWrapper src={MMLogo} />
            </a>

            <RightColumn>
                <strong>contact:</strong>
                <a
                    href="http://chris.madmodels3d.com/"
                    target="_blank"
                >
                    chris.madmodels3d.com
                </a>
                <a
                    href="https://www.linkedin.com/in/chris-demontigny-793983203/"
                    target="_blank"
                >
                    LinkedIn
                </a>
                <i>chrisdemontigny@yahoo.com</i>
            </RightColumn>
        </FooterContainer>
    )
}

export default Footer