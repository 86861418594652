import styled from "styled-components";

// --- color palette --- //
export const orange = '#E78B00'
export const light_Brown = '#AE8A6A'
export const paper = '#FFF7E9'
export const grey = '#DFE0DF'

// --- For centering small components on the screen --- //
export const Overlay = styled.div`
    width:100vw;
    height:100vh;
    left:0px;
    top:0px;
    z-index:2;
    position:fixed;
    pointer-events:none;

    @media (max-height:330px) {
        display:flex;
        flex-direction:column;
    }
`