import styled from "styled-components";
import { orange } from "../../global-styles/gstyles.styles";

export const LoadingContainer = styled.div`
    // position:absolute;
    position:fixed;
    // right: 10vw;
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index:2;

    img {
        // position:absolute;
        z-index:1;
        // top:33%;
        // background-color:blue;
    }
    
    // box-shadow: 0 4px 3px 0  rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    `
    
    // --- Dark screen effect --- //
    export const LoadingOverlay = styled.div`
        background-color:#fff;
        position:absolute;
        width: 100%;
        height:100%;
        opacity:.4;
        background-color:#000;
        transition: all 500ms;
        z-index:0;
        top:-8px;
    `
    
    export const LoadingText = styled.p`
    // position: absolute;
    font-size: 1.4rem;
    color: #fff;
    background-color: ${orange};
    padding-left:5px;
    padding-right:5px;
    // background: rgba(0, 0, 0, 1)
    z-index:1;
`

// .loading-hold {
//     position:absolute;
//     right: 10vw;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     /* color: darkblue; */
//   }

//   .loading-text {
//       position: absolute;
//     font-size: 1.4rem;
//     color: #fff;
//   }