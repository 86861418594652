import styled from "styled-components";

export const FooterContainer = styled.footer`
    // max-width:800px;
    max-width:800px;
    height:100px;
    margin:auto;
    display:flex;
    justify-content:space-around;
    border-top:solid 1px;
    // position:sticky;
    bottom:0px;
    background-color:#fff;
    z-index:1;
`

export const RightColumn = styled.div`
    display:flex;
    flex-direction:column;

    // background-color:blue;

`
        
    export const ImageWrapper = styled.img`
    width:100px;
    height:100px;
`