import {Routes,Route,BrowserRouter,Switch,Outlet} from 'react-router-dom'
import React from 'react'
import Home from './home.component'

export default (
    <BrowserRouter>
        <Routes>
            <Route path='/:chapter?' element={<Home />} />
        </Routes>
    </BrowserRouter>
)