import { createContext,useState,useEffect } from "react";
import { useBooleanState } from 'webrix/hooks';
import axios from "axios";


export const NotebookContext = createContext({
    blog:null,
    setBlogs:() => null,

    isLoading:false,
    setIsLoading:() => null,

    rawIpynb:null,
    setRawIpynb:() => null,

    noticeContent:null,
    setNoticeContent:() => null,

    updateAvailable:false,
    setUpdateAvailable:() => null,

    alert:null,
    setAlert:() => null,

    isOnline:null,
    setIsOnline:() => null,

    selectedCategory:null,
    setSelectedCategory:() => null,

    // --- For copying notebook name in other components --- //
    notebookName:null,
    setNotebookName:() => null

});
export const NotebookProvider = ({ children }) => {
    // const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(navigator.onLine);
    // console.log('NAVIGATOR',navigator)
    useEffect(() => {
        checkForDownloadedNotebooks()
        // console.log('items',window.addEventListener('online'))

        // window.addEventListener('online', setOnline);
        // window.addEventListener('offline', setOffline);

        // return () => {
        //     window.removeEventListener('online', setOnline);
        //     window.removeEventListener('offline', setOffline);
        // };
    },[])

    const [blog,setBlogs] = useState(null)
    const [isLoading,setIsLoading] = useState(false)
    const [rawIpynb,setRawIpynb] = useState({})
    const [noticeContent,setNoticeContent] = useState(null)
    const [updateAvailable,setUpdateAvailable] = useState(false)
    const [alert,setAlert] = useState(null)
    const [isOnline,setIsOnline] = useState(null)
    const [selectedCategory,setSelectedCategory] = useState(null)
    const [notebookName,setNotebookName] = useState(null)

    const checkForDownloadedNotebooks = () => {
        const windowItems = localStorage.getItem('NOTE_BOOKS')
        try {
            if (windowItems != null && windowItems != 'null') {
                const parsedBooks = JSON.parse(localStorage.getItem('NOTE_BOOKS'))
                setBlogs(parsedBooks)
            }
        } catch (err) {
            console.log('THERE IS AN ERROR IN BOOK CONTEXT',err)
        }
    }

    // --- THIS IS FOR LOADING NOTEBOOKS FROM THE PATH PARAMETER --- //
    // const loadParams = () => {
    //     const location = window.location.pathname.split('/')[1].split('%20').join(' ')
    //     const findDocument = blog.filter(el => el.name === location)[0]
    //     console.log('params',findDocument)
    //     try {
    //         const findDocument = blog.filter(el => el.name === location)[0]
    //         const loadToState = {'name':findDocument.name,source:findDocument.source}
    //         console.log('ARE YOU LOOKING FOR THIS?',loadToState)
    //         // setState({
    //         //   ...state,
    //         //   rawIpynb:{findDocument[0].name,}
              
    //         // })
    //       } catch (err) {
    //         console.log("couldnt find the document you're looking for",err)
    //       }
    // }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }
    
    const callBlogs = async (callback) => {
        setIsLoading(true)
        timeout(2000).then(res => {
            console.log('calling blogs')
            axios.get('/api/notebook/get/all').then(res => {
                const parsedItems = JSON.stringify(res.data)
                setBlogs(res.data)
                localStorage.setItem('NOTE_BOOKS',parsedItems)
                if(callback != undefined) {
                    callback()
                }
                setIsLoading(false)
                setUpdateAvailable(false)
                return (res.data)
            }).catch(err => {
                setIsLoading(false)
                setAlert('There was an error loading the documents, please try again later.')
                console.log('error in calling blogs',err)
                return
            })  
        })
        // console.log('calling blogs')
        // await axios.get('/api/notebook/get/all').then(res => {
        //     // console.log('hit call blogs',res.data)
        //     const parsedItems = JSON.stringify(res.data)
        //     setBlogs(res.data)
        //     localStorage.setItem('NOTE_BOOKS',parsedItems)
        //     setIsLoading(false)
        //     return (res.data)
        // }).catch(err => {
        //     setIsLoading(false)
        //     console.log('error in calling blogs',err)
        // })
        // await loadParams()

    }
    
    // console.log('hit book.context.js',children)

    const value = {
        blog,
        callBlogs,

        isLoading,
        setIsLoading,

        rawIpynb,
        setRawIpynb,

        noticeContent,
        setNoticeContent,

        updateAvailable,
        setUpdateAvailable,

        alert,setAlert,

        isOnline,setIsOnline,

        selectedCategory,setSelectedCategory,

        notebookName,setNotebookName

    }

    return (
        <NotebookContext.Provider value={value}>
            {children}
        </NotebookContext.Provider>
    )
}