import { createContext,useState,useEffect } from "react";

export const CodeContext = createContext({
    mediaAlign: "left",
    displaySource: "auto",
    displayOutput: "auto",
    showLineNumbers: false,
    codeBlockStyles: undefined,
    locations:'',
    displayPython:true,
    setCodeState:() => null,
    codeState:null
})

export const CodeProvider = ({children}) => {
    const [codeContextState, setCodeContextState] = useState({
        mediaAlign: "left",
        displaySource: "auto",
        displayOutput: "auto",
        showLineNumbers: false,
        codeBlockStyles: undefined,
        displayPython:"true",
        locations:'',
      })

      return (
        <CodeContext.Provider value={{codeContextState,setCodeContextState}}>
            {children}
        </CodeContext.Provider>
      )
}