import React, {useState,useContext,useEffect} from 'react';
import { NotebookContext } from './Components/Context/book.context';
import { CodeContext } from './Components/Context/code.context'
// import Notice from './Components/Notice/notice.component';
// import Notice from './Components/Messages/Notice/notice.component';
// import Alert from './Components/Messages/Alert/alert.component';
// import { NoticeOverlay } from './Components/Notice/notice.styles';
// import { Overlay } from './global-styles/gstyles.styles';
// import { copyText } from './Components/SVG/SVG';
// import BaseIcon from './Components/SVG/svg.component';
// import Loading from './Components/Loading/loading.component';
// import nb_test2 from "./nb_test.json"
// import nb_test from "./nb_test.ipynb"
// import nb_test from "./experimental copy.json"
// import nb_test3 from './Passenger Predictions.json'
// import Heading from './Components/Heading/heading.components';
// import Categories from './Components/Categories/cats.components';
import MathChapters from './Components/Chapters/Math/math.component';
import MachineLearning from './Components/Chapters/MachineLearning/ml.component';
import BlogChapters from './Components/Chapters/Blog/blog.component';
import welcomeNotebook from './welcome.json'
// import hljsStyles from "./lib/hljsStyles";

// import Viewer from './Components/PDF-Viewer/pdf.component';

// import Editor from './lib/Editor'

import JupyterViewer from "./lib/JupyterViewer";
import { AppContainer } from './home.styles';
import axios from 'axios';

const welcome = {name:'welcome',source:welcomeNotebook}

function Home() {
  const [state, setState] = useState({
    rawIpynb:welcome,
    mediaAlign: "left",
    displaySource: "auto",
    displayOutput: "auto",
    showLineNumbers: false,
    codeBlockStyles: undefined,
    locations:'',
  })
  const location = window.location.pathname.split('/')[1].split('%20').join(' ')
  var chapter = location[1]
  var document = location[2]
  // -- ALL NOTEBOOKS IN CONTEXT -- //
  const {
    isLoading,

    callBlogs,blog,

    setNoticeContent,

    setUpdateAvailable,

    alert,

    selectedCategory,

    setNotebookName,
    notebookName
  } = useContext(NotebookContext)

  const {
    setCodeContextState,
    codeContextState
  } = useContext(CodeContext)
  const { displayPython } = codeContextState
  
  useEffect(() => {
    // console.log('here is the location',location)
    // const mathItems = blog.filter(el => el.category === 'Math')
    initializeInfo()

    // try {
    //   const values = chapter.split('')
    //   if (values.length > 0 && values.join('') && wasRouted === null ) {
    //     setState({
    //       ...state,
    //       selectedCategory:chapter,
    //       wasRouted:true,
    //       locations:location
    //     })
    //     chapter = ''
    //   }
    // } catch (err) {
    //   return err
    // }
    setNotebookName(state.rawIpynb.name)
  },[blog,localStorage.getItem('NOTE_BOOKS'),notebookName])

  const initializeInfo = async () => {
    if (localStorage.getItem('NOTE_BOOKS') === null) {
      await callBlogs(loadParams)
    } else {
      await checkForUpdates()
      await loadParams()
    }
  }

  const checkForUpdates = async () => {
    const localItems = localStorage.getItem('NOTE_BOOKS').length
    // console.log('HIT UPDATE',localItems)
    try {
      await axios.get(`/api/notebook/count/${localItems}`).then(res => {
        setUpdateAvailable(res.data)
      })

    } catch (err) {
      console.log('error finding the notebooks')
    }
  }

  // --- Check for link to notebook in params --- //
  const loadParams = () => {
    try {
      const localDocs = localStorage.getItem('NOTE_BOOKS')
      const findDocument = JSON.parse(localDocs).filter(el => el.name === location)[0]
      const loadToState = {'name':findDocument.name,source:JSON.parse(findDocument.source)}
      setState({
        ...state,
        rawIpynb:loadToState
      })
    } catch (err) {
      console.log("couldnt find the document you're looking for")
    }
  }

  // const copy = (location) => {
  //   setNoticeContent(`copied link`)
  //   const formatLocation = location.split(' ').join('%20')
  //   navigator.clipboard.writeText(formatLocation)
  // }

  // const setNewIpynbItem = (params) => {
    // const reader = new FileReader();
    // JSON.parse(params.source)
    // console.log('here are the params',params.source)

    // reader.readAsText(params.source, "UTF-8");
    // reader.onload = (e) => {
    //   setState({...state, rawIpynb: {name:'name',source:JSON.parse(e.target.result)}})
    // };
  // }

  // const switchNotebook = (item) => {
  //   console.log('hit switch view',item)
  //   // setNotebookName(state.rawIpynb.name)
  //   setState({...state,rawIpynb:item})
  // }

  return (

      <AppContainer>
      <div
        style={{
          display: "flex",
          flexDirection:'column'
        }}
      >
        
        {selectedCategory === 'math' &&
        <MathChapters
          state={state}
          setState={setState}
        />}

        {selectedCategory === 'ml' && <MachineLearning
          state={state}
          setState={setState}
        />}

        {selectedCategory === 'blog' && <BlogChapters
          state={state}
          setState={setState}
        />}
  
        {/* <input
        style={{
          width:'90px',
          margin:'auto',
          bottom:'0px',
          paddingBottom:'10px'
        }}
          name="rawIpynb"
          type="file"
          accept=".ipynb"
          onChange={e => {
            if (e.target.files[0]) {
              const reader = new FileReader();
              reader.readAsText(e.target.files[0], "UTF-8");

              reader.onload = (e) => {
                setState({...state, rawIpynb: {name:'name',source:JSON.parse(e.target.result)}})
              };
              reader.onerror = (e) => {
                alert("Only .ipynb files can be loaded here")
                console.log('reader error!', e)
              };
            }
          }}
        /> */}

        {/* <div>
          <label htmlFor="mediaAlign">mediaAlign</label>
          <select
            name="mediaAlign"
            onChange={e => {
              setState({...state, mediaAlign: e.target.value})
            }}
          >
            <option value="left">left</option>
            <option value="center">center</option>
            <option value="right">right</option>
          </select>
        </div> */}

        {/* <Editor /> */}

        {/* <div>
          <label htmlFor="displayOutput">displayOutput</label>
          <select
            name="displayOutput"
            onChange={e => {
              setState({...state, displayOutput: e.target.value})
            }}
          >
            <option value="auto">auto</option>
            <option value="hide">hide</option>
            <option value="show">show</option>
            <option value="scroll">scroll</option>
          </select>
        </div> */}

        {/* <div>
          <label htmlFor="showLineNumbers">showLineNumbers</label>
          <select
            name="showLineNumbers"
            onChange={e => {
              setState({...state, showLineNumbers: e.target.value === 'show'})
            }}
          >
            <option value="show">show</option>
            <option value="hide">hide</option>
          </select>
        </div> */}

        {/* <div>
          <label htmlFor="hljsStyle">hljsStyle</label>
          <select
            name="hljsStyle"
            onChange={e => {
              setState({
                ...state,
                codeBlockStyles: e.target.value === 'default' ? undefined : {
                  hljsStyle: e.target.value,
                }
              })
            }}
          >
            <option key="default" value="default">DEFAULT</option>
            {Object.keys(hljsStyles).map(name => (
              <option key={name} value={name}>{name}</option>
            ))}
          </select>
        </div> */}

      </div>
      {/* <div>
        <Viewer />
      </div> */}
      {!state.rawIpynb ? null :
        <JupyterViewer
          rawIpynb={state.rawIpynb.source}
          mediaAlign={state.mediaAlign}
          showLineNumbers={state.showLineNumbers}
          displaySource={state.displaySource}
          // displaySource={displaySource}
          displayOutput={state.displayOutput}
          codeBlockStyles={state.codeBlockStyles}
          // displayPython={state.displayPython}
          displayPython={displayPython}
        />
      }
    </AppContainer>
  )
}

export default Home;