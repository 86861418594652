import styled, { css } from "styled-components";

import { BaseMessageContainer } from "../message.styles";

const hidden = css`
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 4s, opacity 4s linear;
    pointer-events:none;
`

// export const NoticeOverlay = styled.div`
//     width:100vw;
//     height:100vh;
//     left:0px;
//     z-index:0;
//     position:fixed;
//     pointer-events:none;
// `

export const NoticeContainer = styled(BaseMessageContainer)`
    // border-radius:10px;
    // display:flex;
    // z-index:1000000000000000000000000;
    // box-shadow:10px 5px 60px 10px rgba(36, 36, 36, 1);
    // top:125px;
    // margin:auto;
    // position:relative;
    // height:200px;
    // width:290px;

    // strong {
    //     margin:auto;
    // }
    
    ${({noticeContent}) => !noticeContent && hidden}
`