import { AlertBox, AlertOverlay } from "./alert.styles";
import { NotebookContext } from "../../Context/book.context";
import { useContext,useState } from "react";

const Alert = () => {

    const { alert,setAlert } = useContext(NotebookContext)

    const handler = () => {
        console.log('hit handler')
    }

    return (
    <AlertOverlay>
        <AlertBox>
            <strong>{alert}</strong>
            <button onClick={() => setAlert(null)}>close</button>
        </AlertBox>
    </AlertOverlay>
    )
}

export default Alert