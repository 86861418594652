import React, {useState} from "react";
import { HeadingContainer } from "./heading.styles";
import { gitBash } from "../SVG/SVG";

function Heading() {

    const goHome = () => {
        window.location.pathname = '/'
    }
    
    return (
        <HeadingContainer>
            <section>
                <a
                    href='https://github.com/Nefari0'
                    target="_blank"
                >
                    {gitBash()}
                </a>

                <a
                    href="http://chris.madmodels3d.com/"
                    target="_blank"
                >
                    by Chris deMontigny
                </a>
            </section>
            <h1 onClick={() => goHome()} >The Jupyter Complex</h1>
        </HeadingContainer>
    )
}

export default Heading